import React from 'react'
import Layout from 'components/layout'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const SupportPage = props => {
  return (
    <Layout>
      <Container>
        <h1>Support</h1>
        <pre
          css={css`
            max-width: 400px;
            padding: 1.5rem;
          `}
        >
          <div>
            /toast ask <b>your_question</b>
          </div>
        </pre>
        <p>right in Slack with quick feedback 👋</p>
        <p>
          email us at &nbsp;
          <a href="mailto:support@toast.ninja">support@toast.ninja</a>
          &nbsp; 📝
        </p>
        <p>
          join us on &nbsp;
          <a href="https://spectrum.chat/toast-ninja">Spectrum</a>
          &nbsp; 💬
        </p>
        <p>
          tweet Rea &nbsp;
          <a href="https://twitter.com/rea_loretta">@rea_loretta</a>
          &nbsp; 🦉
        </p>
      </Container>
    </Layout>
  )
}

const Code = styled.span`
  padding: 3px;
  color: #404040;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export default SupportPage
